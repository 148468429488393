<template>
  <card-container backButton closeButton>
    <div class="flex flex-col px-12 pt-24 pb-6">
      <div class="text-3xl text-center">Endre passord</div>
      <form @submit.prevent="changePassword" class="flex flex-col items-center gap-2 mt-10">
        <input
          type="password"
          ref="passwordInput"
          v-model="user.password"
          placeholder="Passord"
          class="w-full p-2 rounded-lg focus:outline-none"
          :class="{ 'ring-2 ring-red-500': passwordInvalid }"
        />
        <input
          type="password"
          ref="passwordNewInput"
          v-model="user.passwordNew"
          placeholder="Nytt passord"
          class="w-full p-2 rounded-lg focus:outline-none"
          :class="{ 'ring-2 ring-red-500': passwordNewInvalid }"
        />
        <input
          type="submit"
          :value="loading ? 'Lagrer...' : 'Lagre'"
          :disabled="loading"
          class="px-10 py-4 text-lg bg-gray-200 rounded-full mt-6f disabled:opacity-50"
        />
        <transition name="fade">
          <div v-show="error" class="mt-6 text-center text-red-600">{{ errorMessage }}</div>
        </transition>
      </form>
    </div>
  </card-container>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import CardContainer from '../components/CardContainer.vue'

export default {
  name: 'UserPasswordEdit',
  components: { CardContainer },
  setup() {
    const store = useStore()
    const router = useRouter()

    const loading = ref(false)
    const error = ref(false)
    const errorMessage = ref()

    const user = ref({
      password: '',
      passwordNew: '',
    })

    const passwordInput = ref()
    const passwordInvalid = ref(false)
    const passwordNewInput = ref()
    const passwordNewInvalid = ref(false)

    const changePassword = () => {
      loading.value = true
      error.value = false
      errorMessage.value = ''
      passwordInvalid.value = false
      passwordNewInvalid.value = false
      store
        .dispatch('changePassword', user.value)
        .then(() => {
          router.push({ name: 'menu', params: { transition: 'slide-forward' } })
        })
        .catch(err => {
          error.value = true
          loading.value = false
          switch (err.code) {
            case 'auth/wrong-password':
              user.value.password = ''
              passwordInput.value.focus()
              passwordInvalid.value = true
              errorMessage.value = 'Passord er ikke riktig'
              break
            case 'auth/weak-password':
              user.value.passwordNew = ''
              passwordNewInput.value.focus()
              passwordNewInvalid.value = true
              errorMessage.value = 'Passord må inneholde minst 6 tegn'
              break
            default:
              errorMessage.value = err.message
          }
        })
    }

    return {
      loading,
      error,
      errorMessage,
      user,
      passwordInput,
      passwordInvalid,
      passwordNewInput,
      passwordNewInvalid,
      changePassword,
    }
  },
}
</script>
